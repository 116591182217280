<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <base-input
        :label="`${$t('RESELLER_INVOICES.ITEM_TYPE')}`"
        v-if="!resellerInvoiceItem.id"
      >
        <el-select
          name="Type"
          v-model="resellerInvoiceItemModel.resellerInvoiceable.type"
          prepend-icon="fas fa-calendar"
        >
          <el-option :value="null" :label="$t('COMMON.NONE')" />
          <el-option value="reseller-products" :label="$t('COMMON.PRODUCT')" />
          <el-option value="reseller-services" :label="$t('COMMON.SERVICE')" />
          <el-option value="packages" :label="$t('COMMON.PACKAGE')" />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.resellerInvoiceable" />

      <div>
        <base-input
          v-if="
            resellerInvoiceItemModel.resellerInvoiceable.type ==
            'reseller-products'
          "
          :label="`${$t(`COMMON.PRODUCT`)} (*)`"
        >
          <reseller-product-selector
            :filterReseller="resellerInvoice.reseller.id"
            :product="resellerInvoiceItemModel.resellerInvoiceable.id"
            :allowNone="false"
            :showAll="false"
            @resellerProductChanged="
              (productId, product) => {
                resellerInvoiceItemModel.resellerInvoiceable.id = productId;
                resellerInvoiceItemModel.unit_price = product.price;
                resellerInvoiceItemModel.code = product.code;
                resellerInvoiceItemModel.excerpt = product.denomination;
              }
            "
          />
        </base-input>
        <base-input
          v-if="
            resellerInvoiceItemModel.resellerInvoiceable.type ==
            'reseller-services'
          "
          :label="`${$t(`COMMON.SERVICE`)} (*)`"
        >
          <reseller-service-selector
            :filterReseller="resellerInvoice.reseller.id"
            :service="resellerInvoiceItemModel.resellerInvoiceable.id"
            :allowNone="false"
            :showAll="false"
            @resellerServiceChanged="
              (serviceId, service) => {
                resellerInvoiceItemModel.resellerInvoiceable.id = serviceId;
                resellerInvoiceItemModel.unit_price = service.price;
                resellerInvoiceItemModel.code = service.code;
                resellerInvoiceItemModel.excerpt = service.denomination;
              }
            "
          />
        </base-input>
        <base-input
          v-if="resellerInvoiceItemModel.resellerInvoiceable.type == 'packages'"
          :label="`${$t(`COMMON.PACKAGE`)} (*)`"
        >
          <package-selector
            :filterReseller="resellerInvoice.reseller.id"
            :package="resellerInvoiceItemModel.resellerInvoiceable.id"
            :allowNone="false"
            :showAll="false"
            @packageChanged="
              (packId, pack) => {
                resellerInvoiceItemModel.resellerInvoiceable.id = packId;
                resellerInvoiceItemModel.unit_price = pack.price;
                resellerInvoiceItemModel.code = pack.code;
                resellerInvoiceItemModel.excerpt = pack.denomination;
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.resellerInvoiceable" />
      </div>

      <base-input
        :label="`${$t('COMMON.CODE')} (*)`"
        :placeholder="$t('COMMON.CODE')"
        v-model="resellerInvoiceItemModel.code"
        input-classes="form-control-alternative"
        v-if="!resellerInvoiceItemModel.resellerInvoiceable.type"
      >
      </base-input>
      <validation-error
        v-if="!resellerInvoiceItemModel.resellerInvoiceable.type"
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="resellerInvoiceItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <base-input
        :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
        :placeholder="$t('COMMON.UNIT_PRICE')"
        v-model="resellerInvoiceItemModel.unit_price"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.unit_price" />

      <base-input
        :label="`${$t('COMMON.QUANTITY')} (*)`"
        :placeholder="$t('COMMON.QUANTITY')"
        v-model="resellerInvoiceItemModel.quantity"
        type="number"
        step="1"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.quantity" />

      <base-input
        :label="$t('COMMON.DISCOUNT_PERCENT')"
        :placeholder="$t('COMMON.DISCOUNT_PERCENT')"
        v-model="resellerInvoiceItemModel.discount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.discount" />
    </form>

    <template slot="footer">
      <button
        v-if="!resellerInvoiceItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitResellerInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE_ITEM") }}
      </button>

      <base-button
        v-if="resellerInvoiceItemModel.id"
        native-type="submit"
        type="primary"
        class="new-resellerInvoice-item--add"
        @click="submitResellerInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("RESELLER_INVOICES.UPDATE_RESELLER_INVOICE_ITEM") }}
      </base-button>

      <base-button
        v-if="resellerInvoiceItemModel.id"
        type="danger"
        @click="deleteResellerInvoiceItem(resellerInvoiceItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("RESELLER_INVOICES.DELETE_RESELLER_INVOICE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeResellerInvoiceItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ResellerProductSelector from "@/components/ResellerProductSelector.vue";
import ResellerServiceSelector from "@/components/ResellerServiceSelector.vue";
import PackageSelector from "@/components/PackageSelector.vue";
import defaultResellerInvoiceItem from "../defaultResellerInvoiceItem";

export default {
  name: "resellerInvoice-view-details-resellerInvoice-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    ResellerProductSelector,
    ResellerServiceSelector,
    PackageSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["resellerInvoice", "resellerInvoiceItem", "showModal", "loading"],

  data() {
    return {
      all_resellerInvoiceables: [],
      resellerInvoiceItemModel: cloneDeep(defaultResellerInvoiceItem),
    };
  },

  computed: {},

  methods: {
    closeResellerInvoiceItemModal() {
      this.$emit("onCloseResellerInvoiceItemModal", true);
    },

    submitResellerInvoiceItemForm() {
      const resellerInvoiceItemData = cloneDeep(this.resellerInvoiceItemModel);
      resellerInvoiceItemData.unit_price = parseFloat(
        resellerInvoiceItemData.unit_price
      );
      resellerInvoiceItemData.quantity = parseInt(
        resellerInvoiceItemData.quantity,
        10
      );
      resellerInvoiceItemData.discount = parseFloat(
        resellerInvoiceItemData.discount
      );
      if (!resellerInvoiceItemData.resellerInvoiceable.id) {
        resellerInvoiceItemData.resellerInvoiceable = null;
      }

      this.$emit("onSubmitResellerInvoiceItemForm", resellerInvoiceItemData);
    },

    deleteResellerInvoiceItem(resellerInvoiceItemId) {
      this.$emit("onDeleteResellerInvoiceItem", resellerInvoiceItemId);
    },
  },

  mounted() {},

  watch: {
    resellerInvoiceItem(resellerInvoiceItem) {
      if (resellerInvoiceItem) {
        this.resellerInvoiceItemModel = cloneDeep(resellerInvoiceItem);
      }
      this.resetApiValidation();
    },
  },
};
</script>
