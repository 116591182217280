<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ resellerInvoice.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(resellerInvoice.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("RESELLER_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(resellerInvoice.expiration_time) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="resellerInvoice.reseller" />
          </dd>
        </dl>

        <dl class="row" v-if="resellerInvoice.resellerInvoice">
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER_ORDER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="resellerInvoice.resellerInvoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="resellerInvoice.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <reseller-invoice-status-badge :resellerInvoice="resellerInvoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="resellerInvoice.pricing">
            <span> {{ $formatCurrency(resellerInvoice.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                $formatCurrency(
                  resellerInvoice.total_paied ? resellerInvoice.total_paied : 0
                )
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="resellerInvoice.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="resellerInvoice.excerpt">
            <div v-html="resellerInvoice.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <dl class="row" v-if="resellerInvoice.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_lastname }}
          </dd>
        </dl>

        <dl class="row" v-if="resellerInvoice.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_firstname }}
          </dd>
        </dl>

        <dl class="row" v-if="resellerInvoice.billing_entity_type != 'N/A'">
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ resellerInvoice.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import ResellerInvoiceStatusBadge from "./ResellerInvoiceStatusBadge.vue";

export default {
  name: "resellerInvoice-view-global",

  components: {
    ResellerInvoiceStatusBadge,
  },

  props: ["resellerInvoice"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerInvoice(resellerInvoice) {},
  },
};
</script>
